import "./styles.scss";

import { useState, useEffect } from "react";
import React from "react";
import { Rnd } from "react-rnd";
import morningqs from "../morningqs";
import { useNavigate } from "react-router-dom";
import Timer from "./Timer";
import { useParams } from "react-router-dom";

function HomePage({
  htmlMon,
  htmlTues,
  htmlWeds,
  htmlThurs,
  htmlFri,
  setHtmlMon,
  setHtmlTues,
  setHtmlWeds,
  setHtmlThurs,
  setHtmlFri,
  indexQ,
  setIndexQ,
  morningQsArr,
  setMorningQsArr,
  fontSize,
  setFontSize,
  fontSizeQ,
  setFontSizeQ,
  timerIsOpen,
  setTimerIsOpen,
  questionIsOpen,
  setQuestionIsOpen,
  todayScheduleIsOpen,
  setTodayScheduleIsOpen,
  currentUser,
  setCurrentUser,
  startTime,
}) {
  const currentUserId = useParams().id;
  const [loading, setLoading] = useState(true);
  const [currentUserFromApi, setCurrentUserFromApi] = useState({});

  function getCurrentUserFromApi() {
    fetch(`https://next-morningmoodz-api.vercel.app/api/${currentUserId}`)
      .then((res) => res.json())
      .then((data) => {
        setCurrentUserFromApi(data);
        setHtmlMon(data.html.monday);
        setHtmlTues(data.html.tuesday);
        setHtmlWeds(data.html.wednesday);
        setHtmlThurs(data.html.thursday);
        setHtmlFri(data.html.friday);
        setIndexQ(data.morningQIndex);
        setMorningQsArr(data.morningQs);
        setFontSize(data.fontSizeSchedule);
        setFontSizeQ(data.fontSizeQ);
        setCurrentUser(data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getCurrentUserFromApi();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <section className="home-container">
      <UpperNavHome currentUserFromApi={currentUserFromApi} />
      <Display
        htmlMon={htmlMon}
        htmlTues={htmlTues}
        htmlWeds={htmlWeds}
        htmlThurs={htmlThurs}
        htmlFri={htmlFri}
        indexQ={indexQ}
        setIndexQ={setIndexQ}
        morningQsArr={morningQsArr}
        setMorningQsArr={setMorningQsArr}
        fontSize={fontSize}
        setFontSize={setFontSize}
        fontSizeQ={fontSizeQ}
        setFontSizeQ={setFontSizeQ}
        timerIsOpen={timerIsOpen}
        questionIsOpen={questionIsOpen}
        todayScheduleIsOpen={todayScheduleIsOpen}
        startTime={startTime}
        currentUserFromApi={currentUserFromApi}
        currentUserId={currentUserId}
      />
      <LowerNav
        setTimerIsOpen={setTimerIsOpen}
        setQuestionIsOpen={setQuestionIsOpen}
        setTodayScheduleIsOpen={setTodayScheduleIsOpen}
        currentUser={currentUser}
        currentUserId={currentUserId}
      />
    </section>
  );
}

function UpperNavHome({ currentUserFromApi }) {
  const navigate = useNavigate();
  function navigateToSettings() {
    navigate(`/settings/${currentUserFromApi._id}`);
  }

  function navigateToLogin() {
    navigate("/");
    window.location.reload();
  }
  function handleSettingsClick() {
    navigateToSettings();
  }

  function handleSignOutClick() {
    navigateToLogin();
  }

  const formattedDate = new Date()
    .toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .toUpperCase();

  return (
    <nav className="upper-nav-home">
      {/* <button className="save-mood-btn">save this mood</button> */}
      <div className="today-date">{formattedDate}</div>
      <div className="welcome-name">
        <p>{currentUserFromApi.preferredName}</p>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="settings-icon"
          onClick={handleSettingsClick}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="signout-icon"
          onClick={handleSignOutClick}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
          />
        </svg>
      </div>
    </nav>
  );
}

function Display({
  htmlMon,
  htmlTues,
  htmlWeds,
  htmlThurs,
  htmlFri,

  indexQ,
  setIndexQ,

  morningQsArr,
  setMorningQsArr,
  fontSize,
  setFontSize,
  fontSizeQ,
  setFontSizeQ,
  timerIsOpen,
  questionIsOpen,
  todayScheduleIsOpen,
  startTime,
  currentUserFromApi,
  currentUserId,
}) {
  const [index, setIndex] = useState(currentUserFromApi.morningQIndex);

  return (
    <div className="today-schedule-display">
      {todayScheduleIsOpen ? (
        <TodaySchedule
          fontSize={fontSize}
          setFontSize={setFontSize}
          htmlMon={htmlMon}
          htmlTues={htmlTues}
          htmlWeds={htmlWeds}
          htmlThurs={htmlThurs}
          htmlFri={htmlFri}
        />
      ) : null}
      {timerIsOpen ? <Timer startTime={currentUserFromApi.startTime} /> : null}
      {questionIsOpen ? (
        <Question
          indexQ={indexQ}
          setIndexQ={setIndexQ}
          morningQsArr={morningQsArr}
          setMorningQsArr={setMorningQsArr}
          fontSizeQ={fontSizeQ}
          setFontSizeQ={setFontSizeQ}
          currentUserFromApi={currentUserFromApi}
          currentUserId={currentUserId}
          index={index}
          setIndex={setIndex}
        />
      ) : null}
      {/* <BlankTextBox /> */}
    </div>
  );
}

function LowerNav({
  setTimerIsOpen,
  setQuestionIsOpen,
  setTodayScheduleIsOpen,
  currentUser,
  currentUserId,
}) {
  function handleTodayScheduleClick() {
    setTodayScheduleIsOpen((prevState) => !prevState);
  }

  function handleTimerClick() {
    setTimerIsOpen((prevState) => !prevState);
  }

  function handleQuestionClick() {
    setQuestionIsOpen((prevState) => !prevState);
  }

  const navigate = useNavigate();

  function navigateToScheduleBuilder() {
    navigate(`/schedulebuilder/${currentUserId}`);
  }

  function handleScheduleBuilderClick() {
    navigateToScheduleBuilder();
  }
  return (
    <nav className="lower-nav">
      <div className="schedule-builder nav-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="lower-icon builder-icon"
          onClick={handleScheduleBuilderClick}
        >
          <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
        </svg>
        <p className="iconLabel">schedule builder</p>
      </div>
      <div className="today-schedule nav-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          className="lower-icon today-icon"
          onClick={handleTodayScheduleClick}
        >
          <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
        </svg>
        <p className="iconLabel">today's schedule</p>
      </div>
      <div className="timer nav-icon" id="timer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="lower-icon timer-icon"
          id="timer"
          onClick={handleTimerClick}
        >
          <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
        </svg>
        <p className="iconLabel">timer</p>
      </div>

      <div className="morning-q nav-icon" id="morningQ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          className="lower-icon morningq-icon"
          onClick={handleQuestionClick}
        >
          <path d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
        </svg>
        <p className="iconLabel">morning q</p>
      </div>
    </nav>
  );
}

function Question({
  indexQ,
  setIndexQ,
  morningQsArr,
  setMorningQsArr,
  fontSizeQ,
  currentUserFromApi,
  currentUserId,
  index,
  setIndex,
}) {
  const styleQuestion = {
    fontFamily: "Quicksand",
    color: "black",
    fontSize: `${parseInt(fontSizeQ)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "dotted 2px #d3deba",
    // border: "solid 1px #ddd",
    // background: "#f0f0f0",
    borderRadius: "25px",
  };
  const [state, setState] = useState({
    width: 750,
    height: 150,
    x: -150,
    y: -200,
  });

  function handleDragStop(e, d) {
    setState({ ...state, x: d.x, y: d.y });
  }

  function handleResizeStop(e, direction, ref, delta, position) {
    setState({
      ...state,
      width: ref.style.width,
      height: ref.style.height,
      ...position,
    });
  }

  return (
    <Rnd
      style={styleQuestion}
      size={{ width: state.width, height: state.height }}
      position={{ x: state.x, y: state.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
    >
      <PreviousQ
        index={index}
        setIndex={setIndex}
        indexQ={indexQ}
        setIndexQ={setIndexQ}
        morningQsArr={morningQsArr}
        currentUserId={currentUserId}
      />
      <MorningQ
        // indexQ={currentUserFromApi.morningQIndex}
        index={index}
        setIndex={setIndex}
        morningQsArr={morningQsArr}
      />
      <NextQ
        index={index}
        setIndex={setIndex}
        indexQ={indexQ}
        setIndexQ={setIndexQ}
        morningQsArr={morningQsArr}
        currentUserId={currentUserId}
      />
      <CheckBoxQ
        indexQ={indexQ}
        morningQsArr={morningQsArr}
        setMorningQsArr={setMorningQsArr}
        currentUserId={currentUserId}
      />
    </Rnd>
  );
}

function PreviousQ({
  setIndexQ,
  indexQ,
  morningQsArr,
  userId,
  currentUserId,
  index,
  setIndex,
}) {
  function handleClickPrev() {
    const newIndex = indexQ === 0 ? morningQsArr.length - 1 : indexQ - 1;

    setIndex(newIndex);
    setIndexQ(newIndex);

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        morningQIndex: newIndex,
      }),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("morningQIndex updated successfully");
        } else {
          // console.error("Failed to update morningQIndex");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="prev-icon"
      onClick={handleClickPrev}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
}

// function MorningQ({ indexQ }) {
//   return <p className="text">{morningqs[indexQ].question}</p>;
// }

function MorningQ({ indexQ, index }) {
  const [question, setQuestion] = useState("");

  useEffect(() => {
    setQuestion(morningqs[index].question);
  }, [index]);

  return <p className="text">{question}</p>;
}

function NextQ({
  setIndexQ,
  indexQ,
  morningQsArr,
  userId,
  currentUserId,
  index,
  setIndex,
}) {
  function handleClickNext() {
    const newIndex = indexQ === morningQsArr.length - 1 ? 0 : indexQ + 1;

    setIndex(newIndex);
    setIndexQ(newIndex);

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        morningQIndex: newIndex,
      }),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("morningQIndex updated successfully");
        } else {
          // console.error("Failed to update morningQIndex");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="next-icon"
      onClick={handleClickNext}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
}

function CheckBoxQ({ indexQ, morningQsArr, setMorningQsArr, currentUserId }) {
  function handleCheckedQ(id) {
    setMorningQsArr((prevQsArr) =>
      prevQsArr.map((q) => (q.id === id ? { ...q, checked: !q.checked } : q))
    );

    const updatedMorningQs = morningQsArr.map((q) => ({
      id: q.id,
      question: q.question,
      checked: q.id === id ? !q.checked : q.checked,
    }));

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        morningQs: updatedMorningQs,
      }),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("morningQs updated successfully");
        } else {
          // console.error("Failed to update morningQs");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return (
    <input
      className="checkbox"
      checked={morningQsArr[indexQ].checked}
      type="checkbox"
      value={morningQsArr[indexQ]}
      onChange={() => handleCheckedQ(morningQsArr[indexQ].id)}
    />
  );
}

function TodaySchedule({
  htmlMon,
  htmlTues,
  htmlWeds,
  htmlThurs,
  htmlFri,
  fontSize,
}) {
  const StyleShapeOfToday = {
    fontSize: `${parseInt(fontSize)}px`,
    padding: "0.5rem",
    fontFamily: "Quicksand",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "dotted 2px #d3deba",
    borderRadius: "25px",
  };
  const [state, setState] = useState({
    width: 400,
    height: 450,
    x: -650,
    y: -200,
  });

  function handleDragStop(e, d) {
    setState({ ...state, x: d.x, y: d.y });
  }

  function handleResizeStop(e, direction, ref, delta, position) {
    setState({
      ...state,
      width: ref.style.width,
      height: ref.style.height,
      ...position,
    });
  }

  return (
    <Rnd
      style={StyleShapeOfToday}
      size={{ width: state.width, height: state.height }}
      position={{ x: state.x, y: state.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
    >
      <ShapeOfToday
        htmlMon={htmlMon}
        htmlTues={htmlTues}
        htmlWeds={htmlWeds}
        htmlThurs={htmlThurs}
        htmlFri={htmlFri}
      />
    </Rnd>
  );
}

function ShapeOfToday({ htmlMon, htmlTues, htmlWeds, htmlThurs, htmlFri }) {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const formattedHtmlMon = htmlMon.replace(/\n/g, "<br />");

  const htmlContentMon = { __html: formattedHtmlMon };

  const formattedHtmlTues = htmlTues.replace(/\n/g, "<br />");

  const htmlContentTues = { __html: formattedHtmlTues };

  const formattedHtmlWeds = htmlWeds.replace(/\n/g, "<br />");

  const htmlContentWeds = { __html: formattedHtmlWeds };

  const formattedHtmlThurs = htmlThurs.replace(/\n/g, "<br />");

  const htmlContentThurs = { __html: formattedHtmlThurs };

  const formattedHtmlFri = htmlFri.replace(/\n/g, "<br />");

  const htmlContentFri = { __html: formattedHtmlFri };
  return (
    <p
      dangerouslySetInnerHTML={
        currentDayOfWeek === 1
          ? htmlContentMon
          : currentDayOfWeek === 2
          ? htmlContentTues
          : currentDayOfWeek === 3
          ? htmlContentWeds
          : currentDayOfWeek === 4
          ? htmlContentThurs
          : currentDayOfWeek === 5
          ? htmlContentFri
          : htmlContentMon
      }
    ></p>
  );
}

export default HomePage;

function LoadingComponent() {
  return <h1>LOADING...</h1>;
}
