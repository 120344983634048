import { NavLink } from "react-router-dom";

function Landing() {
  return (
    <div className="landing-container">
      <nav className="landingNav">
        <div className="landingNav-left">
          <h1 className="landing-mm">Morning Moodz</h1>
        </div>
        <div className="landingNav-right">
          <NavLink to="/login" className="nav">
            Login
          </NavLink>
          <NavLink to="/signup" className="nav">
            Signup
          </NavLink>
          <NavLink to="/research" className="nav">
            Research
          </NavLink>
        </div>
      </nav>
      <div className="video-container">
        <video className="video-background" autoPlay muted loop>
          <source src="mm-landing.mp4" type="video/mp4" />
        </video>
      </div>
      <main className="landing-main">
        <h2 className="landing-headline">
          RESEARCH-BACKED CLASSROOM TOOL FOR BUILDING COMMUNITY, CRITICAL
          THINKING, AND CREATIVITY.
        </h2>
        <h2 className="landing-sub-headline">
          FASTER, EASIER START TO YOUR MORNING
        </h2>
        <h2 className="landing-sub-headline-2">
          Streamline and simplify your morning routine with a customizable
          display that auto-populates the date, your daily schedule, a morning
          question, and timer.
        </h2>
      </main>
      <article className="nav-article">
        <h1 className="article-why">WHY?</h1>
        <h1 className="article-headline">
          Learning Outcomes Improve When Children Feel Seen and Heard
        </h1>

        <h2 className="article-subtext"></h2>
        <h1 className="article-headline">
          Building Relationships Through Questions in the Classroom Improves
          Classroom Management
        </h1>
      </article>
      <section className="nav-section">
        <h1 className="section-how-it-works">GETTING STARTED</h1>
        <h1 className="steps-headline">
          STEP 1: BUILD YOUR SCHEDULE{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="lower-icon builder-icon"
          >
            <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
          </svg>
        </h1>
        <h2 className="steps-subtext">
          {" "}
          <video className="video-step1" autoPlay muted loop>
            <source src="step1.mp4" type="video/mp4" />
          </video>
        </h2>
        <h1 className="steps-headline">
          STEP 2: CUSTOMIZE YOUR PREFERENCES
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="settings-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </h1>
        <h2 className="steps-subtext">
          {" "}
          <video className="video-step2" autoPlay muted loop>
            <source src="step2.mp4" type="video/mp4" />
          </video>
        </h2>
        <h1 className="steps-headline">
          STEP 3: LET MORNING MOODZ TAKE CARE OF THE REST!
        </h1>
        <h2 className="step3">
          Morning Moodz will: <br />
          ✔️ Populate the current date,
          <br /> ✔️ Populate your schedule for the day, <br />
          ✔️ Generate morning questions,
          <br /> ✔️ Update your preferred fonts and timer start time,
          <br /> ✔️ Display your preferred name/pronouns!
          <br />
          <p className="good-to-start">You're good to start your day! 🥳</p>
        </h2>
      </section>
      <div className="line-break"></div>
      <div className="used-by">
        <h1 className="used-by-text">Used By Teachers In: </h1>
        <div className="district-logos">
          <img src="/vsb.png" alt="vsb" className="district-logo" />
          <img src="/wrdsb.jpeg" alt="wrdsb" className="district-logo" />
          <img src="/sandiego.png" alt="sandiego" className="district-logo" />
          <img src="/thornton.jpeg" alt="thornton" className="district-logo" />
          <img src="/atlanta.jpeg" alt="atlanta" className="district-logo" />
          <img src="/quesnel.png" alt="quesnel" className="district-logo" />
          <img src="/peel.jpeg" alt="peel" className="district-logo" />
          <img src="/northvan.jpeg" alt="nvdsb" className="district-logo" />
          <img src="/tdsb.png" alt="tdsb" className="district-logo" />
        </div>
      </div>
      <footer className="footer">
        <p>© Morning Moodz 2023</p>
        <NavLink to="/contact" className="footer-contact">
          contact
        </NavLink>
      </footer>
    </div>
  );
}

export default Landing;
