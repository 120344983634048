import { useState } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";

function LoginPage({
  loginPassword,
  setLoginPassword,
  loginEmail,
  setLoginEmail,
  users,
  currentUser,
  setCurrentUser,
  usersData,
  setMorningQsArr,
  setHtmlMon,
  setHtmlTues,
  setHtmlWeds,
  setHtmlThurs,
  setHtmlFri,
}) {
  return (
    <div className="login-container">
      <Header />
      <LoginForm
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        users={users}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        usersData={usersData}
        setMorningQsArr={setMorningQsArr}
        setHtmlMon={setHtmlMon}
        setHtmlTues={setHtmlTues}
        setHtmlWeds={setHtmlWeds}
        setHtmlThurs={setHtmlThurs}
        setHtmlFri={setHtmlFri}
      />
    </div>
  );
}

function Header() {
  return <h1 className="header-login">Morning Moodz</h1>;
}

function LoginForm({
  loginPassword,
  setLoginPassword,
  loginEmail,
  setLoginEmail,
  users,
  currentUser,
  setCurrentUser,
  usersData,
  setMorningQsArr,
  setHtmlMon,
  setHtmlTues,
  setHtmlWeds,
  setHtmlThurs,
  setHtmlFri,
}) {
  const navigate = useNavigate();

  function navigateToHome() {
    if (currentUser._id) {
      navigate(`/home/${currentUser._id}`);
    }
  }

  function navigateToSignUp() {
    navigate("/signup");
  }

  function navigateToBuilder() {
    if (currentUser._id) {
      navigate(`/schedulebuilder/${currentUser._id}`);
    }
  }

  const [loginMessage, setLoginMessage] = useState("");
  function checkLoginCredentials(email, password) {
    const validUser = usersData.find(
      (user) => user.email === email && user.password1 === password
    );

    const validUserWrongPassword = usersData.find(
      (user) => user.email === email && user.password1 !== password
    );

    const notValidEmail = usersData.find((user) => user.email === email);

    if (validUser) {
      setCurrentUser(validUser);
      setMorningQsArr(validUser.morningQs);
      setHtmlMon(validUser.html.monday);
      setHtmlTues(validUser.html.tuesday);
      setHtmlWeds(validUser.html.wednesday);
      setHtmlThurs(validUser.html.thursday);
      setHtmlFri(validUser.html.friday);
      setLoginMessage("");
      // navigateToHome();
      navigateToBuilder();
    }

    if (validUserWrongPassword)
      setLoginMessage("Valid Username, but wrong password.");

    if (!notValidEmail)
      setLoginMessage(
        "Looks like you haven't signed up for an account yet! Click SIGN UP to get started!"
      );
  }

  function handleSignInClick(e) {
    e.preventDefault();

    if (!loginEmail || !loginPassword) {
      alert("Please fill in all the fields");
      return;
    }
    checkLoginCredentials(loginEmail, loginPassword);
  }

  function handleSignUpClick(e) {
    e.preventDefault();
    setLoginMessage("");
    setLoginEmail("");
    setLoginPassword("");
    navigateToSignUp();
  }
  return (
    <div className="login-form">
      <div className="email-container">
        <label className="login-label" htmlFor="email">
          Username:
        </label>
        <input
          className="login-inputs"
          id="username"
          placeholder="Enter username..."
          value={loginEmail}
          onChange={(e) => setLoginEmail(e.target.value)}
        ></input>
      </div>
      <div className="password-container">
        <label className="login-label" htmlFor="password">
          Password:
        </label>
        <input
          className="login-inputs"
          id="password"
          placeholder="Enter password..."
          type="password"
          value={loginPassword}
          onChange={(e) => setLoginPassword(e.target.value)}
        ></input>
      </div>
      <button className="login-buttons sign-in-btn" onClick={handleSignInClick}>
        SIGN IN
      </button>
      <div className="or">or</div>
      <button className="login-buttons" onClick={handleSignUpClick}>
        SIGN UP
      </button>
      <p className="login-message">{loginMessage}</p>
    </div>
  );
}

export default LoginPage;
