import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "./styles.scss";
function SettingsPage({
  setFontSize,
  fontSize,
  fontSizeQ,
  setFontSizeQ,
  setMorningQsArr,
  currentUser,
  setCurrentUser,
  users,
  setUsers,
  setStartTime,
  startTime,
  indexQ,
}) {
  const currentUserId = useParams().id;
  const [loading, setLoading] = useState(true);
  const [currentUserFromApi, setCurrentUserFromApi] = useState({});
  function getCurrentUserFromApi() {
    fetch(`https://next-morningmoodz-api.vercel.app/api/${currentUserId}`)
      .then((res) => res.json())
      .then((data) => {
        setCurrentUserFromApi(data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    getCurrentUserFromApi();
  }, []);
  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <div className="settings-container">
      <NavBar
        currentUserFromApi={currentUserFromApi}
        currentUserId={currentUserId}
      />
      <Settings
        currentUser={currentUser}
        users={users}
        setCurrentUser={setCurrentUser}
        setUsers={setUsers}
        currentUserFromApi={currentUserFromApi}
        currentUserId={currentUserId}
      />
      <Resets
        setFontSize={setFontSize}
        fontSize={fontSize}
        fontSizeQ={fontSizeQ}
        setFontSizeQ={setFontSizeQ}
        setMorningQsArr={setMorningQsArr}
        setStartTime={setStartTime}
        startTime={startTime}
        currentUser={currentUser}
        currentUserFromApi={currentUserFromApi}
        currentUserId={currentUserId}
      />
    </div>
  );
}
function NavBar({ currentUserFromApi, currentUserId }) {
  const navigate = useNavigate();
  function navigateToHome() {
    navigate(`/home/${currentUserId}`);
  }
  function navigateToLogin() {
    navigate("/");
    window.location.reload();
  }
  function handleBackArrowClick() {
    navigateToHome();
  }
  function handleSignOutClick() {
    navigateToLogin();
  }
  return (
    <header className="header-settings">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="settings-back"
        onClick={handleBackArrowClick}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
        />
      </svg>
      <h1 className="header-settings-name">Settings </h1>
      <div className="settings-name-signout">
        <p className="settings-name">Welcome, {currentUserFromApi.firstName}</p>
        <button className="settings-signout-btn" onClick={handleSignOutClick}>
          SIGN OUT
        </button>
      </div>
    </header>
  );
}

function Settings({
  currentUser,
  setCurrentUser,
  users,
  setUsers,
  currentUserFromApi,
  currentUserId,
}) {
  const [isEditable, setIsEditable] = useState(false);

  // Create state variables for each field
  const [firstName, setFirstName] = useState(currentUserFromApi.firstName);
  const [lastName, setLastName] = useState(currentUserFromApi.lastName);
  const [preferredName, setPreferredName] = useState(
    currentUserFromApi.preferredName
  );
  const [password, setPassword] = useState(currentUserFromApi.password1);

  function handleFirstNameChange(event) {
    setFirstName(event.target.value);
  }

  function handleLastNameChange(event) {
    setLastName(event.target.value);
  }

  function handlePreferredNameChange(event) {
    setPreferredName(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleSaveChanges() {
    const patchData = {
      firstName,
      lastName,
      email: currentUser.email,
      password1: password,
      preferredName,
    };

    // console.log("Data to be sent in PATCH request:", patchData);

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patchData),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("User data updated successfully");

          setCurrentUser({
            ...currentUser,
            firstName,
            lastName,
            email: currentUser.email,
            password1: password,
            preferredName,
          });

          setIsEditable(false);
        } else {
          // console.error("Failed to update user data");
          setIsEditable(false); // REMOVE THIS LATER!!!
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  function handleEditToggle() {
    setIsEditable(!isEditable);
  }

  return (
    <div className="settings">
      <div className="account-box">
        <p className="my-account-info">
          First name:
          <input
            className="account-input"
            value={firstName}
            onChange={handleFirstNameChange}
            readOnly={!isEditable}
          />
        </p>
        <p className="my-account-info">
          Last name:
          <input
            className="account-input"
            value={lastName}
            onChange={handleLastNameChange}
            readOnly={!isEditable}
          />
        </p>
        <p className="my-account-info">
          Preferred display name / Pronouns:
          <input
            className="account-input"
            value={preferredName}
            onChange={handlePreferredNameChange}
            readOnly={!isEditable}
          />
        </p>
        <p className="my-account-info-email">
          Username:
          <span className="account-email">{currentUserFromApi.email}</span>
        </p>
        <p className="my-account-info-password">
          Password:
          <input
            className="account-input-password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            readOnly={!isEditable}
          />
        </p>
        <div className="settings-account-btns">
          {isEditable ? (
            <button className="save-changes" onClick={handleSaveChanges}>
              Save Changes
            </button>
          ) : (
            <button className="edit-changes" onClick={handleEditToggle}>
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function Resets({
  setFontSize,
  fontSize,
  setFontSizeQ,
  fontSizeQ,
  setMorningQsArr,
  currentUser,
  setStartTime,
  startTime,
  currentUserFromApi,
  currentUserId,
}) {
  const [isEditable, setIsEditable] = useState(false);

  const [fontSizeSchedule, setFontSizeSchedule] = useState(
    currentUserFromApi.fontSizeSchedule
  );
  const [fontSizeQuestions, setFontSizeQuestions] = useState(
    currentUserFromApi.fontSizeQ
  );
  const [timerStartTime, setTimerStartTime] = useState(
    currentUserFromApi.startTime
  );

  function handleFontSizeChange(e) {
    setFontSizeSchedule(e.target.value === "" ? 0 : parseInt(e.target.value));
  }

  function handleFontSizeChangeQ(e) {
    setFontSizeQuestions(e.target.value === "" ? 0 : parseInt(e.target.value));
  }

  function handleStartTimeChange(e) {
    setTimerStartTime(e.target.value === "" ? 0 : parseInt(e.target.value));
  }

  // function handleResetAllQs() {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to reset all the Morning Questions checkboxes? This action can't be undone."
  //   );
  //   if (confirmed) {
  //     setMorningQsArr((prevArr) =>
  //       prevArr.map((question) => {
  //         return { ...question, checked: false };
  //       })
  //     );
  //   }
  // }

  function handleResetAllQs() {
    const confirmed = window.confirm(
      "Are you sure you want to reset all the Morning Questions checkboxes? This action can't be undone."
    );

    if (confirmed) {
      const patchData = {
        morningQs: currentUserFromApi.morningQs.map((question) => ({
          ...question,
          checked: false,
        })),
      };

      const requestPatchObj = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(patchData),
      };

      fetch(
        `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
        requestPatchObj
      )
        .then((response) => {
          if (response.ok) {
            // console.log("Morning Questions reset successfully.");
          } else {
            // console.error("Failed to update morning questions.");
          }
        })
        .catch((error) => {
          // console.error("Error:", error);
        });
    }
  }

  function handleSaveChanges() {
    const patchData = {
      fontSizeSchedule,
      fontSizeQ: fontSizeQuestions,
      startTime: timerStartTime,
    };

    // console.log("Data to be sent in PATCH request:", patchData);

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patchData),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("User data updated successfully");

          setFontSize(fontSizeSchedule);
          setFontSizeQ(fontSizeQuestions);
          setStartTime(timerStartTime);

          setIsEditable(false); // Set isEditable to false after saving
        } else {
          setIsEditable(false); // REMOVE THIS LATER!!!
          // console.error("Failed to update user data");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return (
    <div className="resets">
      <p className="reset-text">- SETS & RESETS -</p>
      <div className="reset-btns">
        <div className="sets-container">
          <label className="set-font-label">
            Set Font Size for Home Schedule:{" "}
            {isEditable ? (
              <input
                className="set-font-input"
                onChange={handleFontSizeChange}
                value={fontSizeSchedule}
              />
            ) : (
              <span>{fontSizeSchedule}</span>
            )}
          </label>
          <label className="set-font-label-HaQ">
            Set Font Size for Questions:{" "}
            {isEditable ? (
              <input
                className="set-font-input-HaQ"
                onChange={handleFontSizeChangeQ}
                value={fontSizeQuestions}
              />
            ) : (
              <span>{fontSizeQuestions}</span>
            )}
          </label>
          <label className="set-timer-start-time">
            Set Timer Start Time (seconds):{" "}
            {isEditable ? (
              <input
                className="set-font-input"
                onChange={handleStartTimeChange}
                value={timerStartTime}
              />
            ) : (
              <span>{timerStartTime}</span>
            )}
          </label>
        </div>
        {isEditable ? (
          <button className="save-changes" onClick={handleSaveChanges}>
            Save Changes
          </button>
        ) : (
          <button className="edit-changes" onClick={() => setIsEditable(true)}>
            Edit
          </button>
        )}
        <button className="reset-morningq" onClick={handleResetAllQs}>
          RESET ALL MORNING Q'S CHECKBOXES
        </button>
      </div>
    </div>
  );
}

function LoadingComponent() {
  return <h1>LOADING...</h1>;
}
export default SettingsPage;
