import React, { useEffect, useState } from "react";
import { FaPlay, FaPause, FaUndo } from "react-icons/fa";

import "./Timer.css";
import { Rnd } from "react-rnd";

import windchimes from "./wind_chimes.mp3";
import useSound from "use-sound";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const TimerContainer = ({ startTime }) => (
  <Rnd
    style={style}
    default={{
      x: 0,
      y: 25,
      width: 320,
      height: 200,
    }}
  >
    <Timer startTime={startTime} />
  </Rnd>
);

const Timer = ({ startTime }) => {
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const [play] = useSound(windchimes);
  const [timerFinished, setTimerFinished] = useState(false);
  const [seconds, setSeconds] = useState(startTime);
  const [isActive, setIsActive] = useState(false);
  const toggle = () => {
    setIsActive(!isActive);
    setTimerFinished(false);
  };
  const reset = () => {
    setSeconds(startTime);
    setIsActive(false);
    setTimerFinished(false);
  };

  useEffect(() => {
    let interval = null;
    if (seconds <= 0) {
      setIsActive(false);
      play();
    }
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((s) => s - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, play]);

  return (
    <div className="app">
      <div className="time">
        <div className={`timer-circle ${isActive ? "timer-circle-grow" : ""}`}>
          {Math.floor(seconds / 60)}:{zeroPad(seconds % 60, 2)}
        </div>
      </div>
      <div className="row">
        <button
          className={`circle-button button-primary-${
            isActive ? "active" : "inactive"
          }`}
          onClick={toggle}
        >
          {isActive ? <FaPause /> : <FaPlay />}
          {/* // 'pause' : 'start'} */}
        </button>
        <button
          className="circle-button button-primary-inactive"
          onClick={reset}
        >
          <FaUndo />
          {/* reset */}
        </button>

        <button
          className="circle-button button-primary-inactive"
          onClick={() => setSeconds((s) => Math.max(0, s + 60))}
        >
          +1min
        </button>
        <button
          className="circle-button button-primary-inactive"
          onClick={() => setSeconds((s) => Math.max(0.0, s - 60))}
        >
          -1min
        </button>
        <div />
      </div>
    </div>
  );
};

export default TimerContainer;
