import "./styles.scss";

import ShapeOfDay from "./ShapeOfDay";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

function BuilderPage({
  htmlMon,
  handleChangeMon,
  htmlTues,
  handleChangeTues,
  htmlWeds,
  handleChangeWeds,
  htmlThurs,
  handleChangeThurs,
  htmlFri,
  handleChangeFri,
  currentUser,
}) {
  const currentUserId = useParams().id;
  const [loading, setLoading] = useState(true);
  const [currentUserFromApi, setCurrentUserFromApi] = useState({});
  function getCurrentUserFromApi() {
    fetch(`https://next-morningmoodz-api.vercel.app/api/${currentUserId}`)
      .then((res) => res.json())
      .then((data) => {
        setCurrentUserFromApi(data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    getCurrentUserFromApi();
  }, []);
  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <section className="builder-container">
      <UpperNav currentUserId={currentUserId} />
      <div className="schedule-builder-display">
        <ShapeOfDay
          currentUserFromApi={currentUserFromApi}
          handleChange={handleChangeMon}
          day="Monday"
        />
        <ShapeOfDay
          currentUserFromApi={currentUserFromApi}
          handleChange={handleChangeTues}
          day="Tuesday"
        />
        <ShapeOfDay
          currentUserFromApi={currentUserFromApi}
          handleChange={handleChangeWeds}
          day="Wednesday"
        />
        <ShapeOfDay
          currentUserFromApi={currentUserFromApi}
          handleChange={handleChangeThurs}
          day="Thursday"
        />
        <ShapeOfDay
          currentUserFromApi={currentUserFromApi}
          handleChange={handleChangeFri}
          day="Friday"
        />
      </div>
      <LowerNav />
    </section>
  );
}

function UpperNav({ currentUserId }) {
  const navigate = useNavigate();

  function navigateToHome() {
    navigate(`/home/${currentUserId}`);
  }

  function handleHomeClick() {
    navigateToHome();
  }

  function navigateToLogin() {
    navigate("/");
    window.location.reload();
  }

  function handleSignOutClick() {
    navigateToLogin();
  }
  return (
    <nav className="upper-nav">
      <div className="nav-icon-builder" onClick={handleHomeClick}>
        HOME
      </div>
      <div className="small-screen-container hidden">
        <div className="nav-message-builder">
          Feel free to make changes to your schedule here, but to view your
          Morning Moodz display, please sign in to your account on a larger
          screen (desktop or laptop computer).{" "}
          <button className="builder-signout-btn" onClick={navigateToLogin}>
            SIGN OUT
          </button>
        </div>
      </div>
    </nav>
  );
}

function LowerNav({
  currentUser,
  htmlMon,
  htmlTues,
  htmlWeds,
  htmlThurs,
  htmlFri,
}) {
  function handleSaveScheduleClick(userId) {
    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        html: {
          monday: htmlMon,
          tuesday: htmlTues,
          wednesday: htmlWeds,
          thursday: htmlThurs,
          friday: htmlFri,
        },
      }),
    };

    fetch(
      `http://localhost:3001/api/morningmoodz-users/${userId}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("User data updated successfully");
        } else {
          // console.error("Failed to update user data");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return <h1></h1>;
}

function LoadingComponent() {
  return <h1>LOADING...</h1>;
}
export default BuilderPage;
