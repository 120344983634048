import { NavLink } from "react-router-dom";

function Research() {
  return (
    <div className="landing-container">
      <nav className="landingNav">
        <div className="landingNav-left">
          <h1 className="landing-mm">Morning Moodz</h1>
        </div>
        <div className="landingNav-right">
          <NavLink to="/" className="nav">
            BACK TO MAIN PAGE
          </NavLink>
        </div>
      </nav>
      <h1 className="journal-headline">
        Learning Outcomes Improve When Children Feel Seen and Heard
      </h1>
      <div className="research-container">
        <a
          href="https://www.journals.uchicago.edu/doi/epdf/10.1086/719121"
          target="_blank"
        >
          <img
            src="/studentvoicejournal1.png"
            alt="Student Voice Journal Article"
            className="research-article"
          />
        </a>
        <a
          href="https://web.colby.edu/ed334/files/2017/08/Mitra-Serriere-Elem-Stud-Voice-2.pdf"
          target="_blank"
        >
          <img
            className="research-article"
            src="/studentvoicejournal2.png"
            alt="Student Voice Journal Article"
          />
        </a>
      </div>

      <h1 className="journal-headline">
        Building Relationships Through Questions in the Classroom Improves
        Classroom Management
      </h1>
      <div className="research-container">
        <a
          href="https://journals.sagepub.com/doi/abs/10.1177/00220574211057044"
          target="_blank"
        >
          <img
            src="/relationshipsjournal1.png"
            alt="Student Voice Journal Article"
            className="research-article"
          />
        </a>
        <a
          href="https://link.springer.com/article/10.1007/s12144-023-04590-5"
          target="_blank"
        >
          <img
            className="research-article"
            src="/relationshipsjournal2.png"
            alt="Student Voice Journal Article"
          />
        </a>
      </div>
    </div>
  );
}

export default Research;
