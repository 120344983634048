import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-container">
      <nav className="landingNav">
        <div className="landingNav-left">
          <h1 className="landing-mm">Morning Moodz</h1>
        </div>
        <div className="landingNav-right">
          <NavLink to="/" className="nav">
            BACK TO MAIN PAGE
          </NavLink>
        </div>
      </nav>
      <div className="contact-form-container">
        <h2 className="contact-text">
          Hi there! Have a question? Feedback for improvements you'd like to
          see? Have a morning question you'd like to add to the question bank?
          Any problems using Morning Moodz? Send us a message!
        </h2>

        {/* Embed Google Form */}
        <iframe
          className="google-form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdWGjbKBlT2SExzUbxQSM3ixrk5pXz7WQPLuq4uP_4rZcrPYQ/viewform"
          width="325vw"
          height="800"
          title="Embedded Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default Contact;

// import { NavLink } from "react-router-dom";
// import React, { useState } from "react";

// function Contact() {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(
//         "https://mm-contact-server.vercel.app/sendEmail",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );

//       if (response.status === 200) {
//         console.log("Email sent successfully");
//         setFormData({
//           name: "",
//           email: "",
//           message: "",
//         });
//       } else {
//         console.error("Error sending email");
//       }
//     } catch (error) {
//       console.error("Error sending email:", error);
//     }
//   };

//   return (
//     <div className="contact-container">
//       <nav className="landingNav">
//         <div className="landingNav-left">
//           <h1 className="landing-mm">Morning Moodz</h1>
//         </div>
//         <div className="landingNav-right">
//           <NavLink to="/" className="nav">
//             BACK TO MAIN PAGE
//           </NavLink>
//         </div>
//       </nav>
//       <div className="contact-form-container">
//         <h2 className="contact-text">
//           Hi there! Have a question? Feedback for the Morning Moodz team?
//           Feedback for improvements you'd like to see? Have a morning question
//           you'd like to add to the question bank? Any problems using Morning
//           Moodz? Send us a message!
//         </h2>
//         <form className="contact-form" onSubmit={handleSubmit}>
//           <div className="label-input-name">
//             <label htmlFor="name">Name:</label>
//             <input
//               className="input-name"
//               type="text"
//               id="name"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="label-input-email">
//             <label htmlFor="email">Email:</label>
//             <input
//               className="input-email"
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="label-input-message">
//             <label htmlFor="message">Message:</label>
//             <textarea
//               className="input-message"
//               id="message"
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//             />
//           </div>

//           <button type="submit" className="contact-submit">
//             Send
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Contact;
