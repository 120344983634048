//SHAPEOF Component - don't repeat.
//Helper function - sync to local storage,
// hey local storage, resync this and store it!
// use effect, update local storage as a side effect of the state changing.
import "./App.css";
import LoginPage from "./Components/LoginPage";
import SignUpPage from "./Components/SignUpPage";
import HomePage from "./Components/HomePage";
import BuilderPage from "./Components/BuilderPage";
import SettingsPage from "./Components/SettingsPage";
import Landing from "./Components/Landing";
import Research from "./Components/Research";
import Contact from "./Components/Contact";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

function App() {
  const [usersData, setUsersData] = useState([]);

  function getUsersFromApi() {
    fetch("https://next-morningmoodz-api.vercel.app/api/users")
      .then((res) => res.json())
      .then((data) => {
        setUsersData(data);
      });
    // .catch((err) => console.log(err));
  }
  useEffect(() => {
    getUsersFromApi();
  }, []);

  const [htmlMon, setHtmlMon] = useState("");

  const handleChangeMon = (evt) => {
    setHtmlMon(evt.target.value);
  };

  const [htmlTues, setHtmlTues] = useState("");

  const handleChangeTues = (evt) => {
    setHtmlTues(evt.target.value);
  };

  const [htmlWeds, setHtmlWeds] = useState("");

  const handleChangeWeds = (evt) => {
    setHtmlWeds(evt.target.value);
  };

  const [htmlThurs, setHtmlThurs] = useState("");

  const handleChangeThurs = (evt) => {
    setHtmlThurs(evt.target.value);
  };

  const [htmlFri, setHtmlFri] = useState("");

  const handleChangeFri = (evt) => {
    setHtmlFri(evt.target.value);
  };

  const [indexQ, setIndexQ] = useState(0);

  const [morningQsArr, setMorningQsArr] = useState([]);

  const [fontSize, setFontSize] = useState(24);
  const [fontSizeQ, setFontSizeQ] = useState(24);

  const [timerIsOpen, setTimerIsOpen] = useState(true);
  const [questionIsOpen, setQuestionIsOpen] = useState(true);
  const [todayScheduleIsOpen, setTodayScheduleIsOpen] = useState(true);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [users, setUsers] = useState([]);

  const [currentUser, setCurrentUser] = useState({});

  const [startTime, setStartTime] = useState(300);
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/research" element={<Research />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/login"
        element={
          <LoginPage
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            users={users}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            usersData={usersData}
            setMorningQsArr={setMorningQsArr}
            setHtmlMon={setHtmlMon}
            setHtmlTues={setHtmlTues}
            setHtmlWeds={setHtmlWeds}
            setHtmlThurs={setHtmlThurs}
            setHtmlFri={setHtmlFri}
          />
        }
      />
      <Route
        path="/signup"
        element={
          <SignUpPage
            users={users}
            setUsers={setUsers}
            setCurrentUser={setCurrentUser}
            setUsersData={setUsersData}
          />
        }
      />

      <Route
        path="/home/:id"
        element={
          <HomePage
            htmlMon={htmlMon}
            htmlTues={htmlTues}
            htmlWeds={htmlWeds}
            htmlThurs={htmlThurs}
            htmlFri={htmlFri}
            setHtmlMon={setHtmlMon}
            setHtmlTues={setHtmlTues}
            setHtmlWeds={setHtmlWeds}
            setHtmlThurs={setHtmlThurs}
            setHtmlFri={setHtmlFri}
            indexQ={indexQ}
            setIndexQ={setIndexQ}
            morningQsArr={morningQsArr}
            setMorningQsArr={setMorningQsArr}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontSizeQ={fontSizeQ}
            setFontSizeQ={setFontSizeQ}
            timerIsOpen={timerIsOpen}
            setTimerIsOpen={setTimerIsOpen}
            questionIsOpen={questionIsOpen}
            setQuestionIsOpen={setQuestionIsOpen}
            todayScheduleIsOpen={todayScheduleIsOpen}
            setTodayScheduleIsOpen={setTodayScheduleIsOpen}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            startTime={startTime}
          />
        }
      />

      <Route
        path="/schedulebuilder/:id"
        element={
          <BuilderPage
            htmlMon={htmlMon}
            handleChangeMon={handleChangeMon}
            htmlTues={htmlTues}
            handleChangeTues={handleChangeTues}
            htmlWeds={htmlWeds}
            handleChangeWeds={handleChangeWeds}
            htmlThurs={htmlThurs}
            handleChangeThurs={handleChangeThurs}
            htmlFri={htmlFri}
            handleChangeFri={handleChangeFri}
            currentUser={currentUser}
          />
        }
      />

      <Route
        path="/settings/:id"
        element={
          <SettingsPage
            setFontSize={setFontSize}
            fontSize={fontSize}
            fontSizeQ={fontSizeQ}
            setFontSizeQ={setFontSizeQ}
            setMorningQsArr={setMorningQsArr}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            users={users}
            setUsers={setUsers}
            setStartTime={setStartTime}
            startTime={startTime}
            indexQ={indexQ}
          />
        }
      />
    </Routes>
  );
}

export default App;
