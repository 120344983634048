const morningqs = [
  {
    id: 1,
    question: "Would you rather live in a treehouse or a cave? ",
    checked: false,
  },
  {
    id: 2,
    question: "If you could be invisible for a day, what would you do? ",
    checked: false,
  },
  {
    id: 3,
    question:
      "Would you rather grow up fast and stay as an adult, or stay a kid forever?",
    checked: false,
  },
  {
    id: 4,
    question: "Would you rather be as big as a giant, or as small as an ant?",
    checked: false,
  },
  {
    id: 5,
    question:
      "Would you rather have telekinesis (the ability to move things with your mind) or telepathy (the ability to read minds)? ",
    checked: false,
  },
  {
    id: 6,
    question:
      "Would you rather time travel 100 years back in history or 100 years into the future? Why?",
    checked: false,
  },
  {
    id: 7,
    question: "Do you believe there is life on other planets? Why or why not? ",
    checked: false,
  },
  {
    id: 8,
    question:
      " Would you rather be able to control the weather or be able to talk to animals?",
    checked: false,
  },
  {
    id: 9,
    question:
      " Would you rather only be able to shout, or only be able to whisper? ",
    checked: false,
  },
  {
    id: 10,
    question:
      "  What is a food that you absolutely, no chance, 0%, not a chance, will NEVER EVER EVER eat again. Why?",
    checked: false,
  },
  {
    id: 11,
    question:
      " WWould you rather be able to teleport, or be able to speak every language?",
    checked: false,
  },
  {
    id: 12,
    question: "Would you rather go to space or the bottom of the ocean?",
    checked: false,
  },
  {
    id: 13,
    question:
      "  Would you rather never watch youtube again, or never watch tv/movies again?",
    checked: false,
  },
  {
    id: 14,
    question: " Would you rather live in a spaceship or a castle?",
    checked: false,
  },
  {
    id: 15,
    question: "Would you rather have a magic wand or a magic carpet? Why?",
    checked: false,
  },
  {
    id: 16,
    question:
      " Would you rather be the strongest person in the world, or the fastest person in the world? Why?",
    checked: false,
  },
  {
    id: 17,
    question:
      " Would you rather discover the cure for a disease, or discover life on a new planet?",
    checked: false,
  },
  {
    id: 18,
    question:
      "Would you rather have a SUPER sense of smell, or a SUPER sense of taste?",
    checked: false,
  },
  {
    id: 19,
    question:
      "If you had to live someone else’s life for a year, who would it be and why?",
    checked: false,
  },
  {
    id: 20,
    question: "Would you rather a pet elephant or a pet owl?",
    checked: false,
  },
  {
    id: 21,
    question:
      "Would you rather be able to control the weather or be able to control the time. Why? ",
    checked: false,
  },

  {
    id: 22,
    question:
      "Would you rather have lots of money but no friends, or lots of good friends but not a lot of money?",
    checked: false,
  },
  {
    id: 23,
    question:
      "Would you rather be stranded in the desert or stranded in the forest? Why?",
    checked: false,
  },
  {
    id: 24,
    question:
      "If you were stranded on an island and could choose only THREE things to have with you, what would they be? ",
    checked: false,
  },
  {
    id: 25,
    question: "Do you remember your very first stuffy? ",
    checked: false,
  },
  { id: 26, question: "Do you remember one of your dreams? ", checked: false },
  {
    id: 27,
    question:
      "What chores do you not mind doing? Which do you dislike the most?",
    checked: false,
  },
  { id: 28, question: "Are you a night owl or an early bird?", checked: false },
  { id: 29, question: "Is there a story behind your name?", checked: false },
  {
    id: 30,
    question: "How would you describe your perfect day?",
    checked: false,
  },
  {
    id: 31,
    question: "What is a movie you have seen more than once that you like?",
    checked: false,
  },
  {
    id: 32,
    question:
      "If you could have an unlimited supply of one thing for the rest of your life, what would you pick? Why?",
    checked: false,
  },
  {
    id: 33,
    question:
      "What is the worst gift or best gift you have ever been given? Why?",
    checked: false,
  },
  {
    id: 34,
    question:
      "If you could instantly be an expert at something, what would it be and why?",
    checked: false,
  },
  {
    id: 35,
    question:
      "If you could hang out with any cartoon character for a day, who would you choose and why? ",
    checked: false,
  },
  {
    id: 36,
    question: "What is one of your favorite food combinations? ",
    checked: false,
  },
  {
    id: 37,
    question:
      "What is the worst gift or best gift you have ever been given? Why?",
    checked: false,
  },
  {
    id: 38,
    question:
      "Would you rather have 50 days of snow in a row or no snow all year?",
    checked: false,
  },
  {
    id: 39,
    question: "What do you like to collect? About how many do you have?",
    checked: false,
  },
  {
    id: 40,
    question: "What do you think is one of the toughest jobs out there?",
    checked: false,
  },
  {
    id: 41,
    question:
      "What is a food you think ketchup would taste interesting with that you have never tried before?",
    checked: false,
  },
  {
    id: 42,
    question: "What is the hardest thing about being a kid?",
    checked: false,
  },
  {
    id: 43,
    question:
      "Would you rather only eat chips or candy for the rest of your life? Which brand/flavour?",
    checked: false,
  },
  {
    id: 44,
    question: "What is something that you hope never changes about yourself?",
    checked: false,
  },
  {
    id: 45,
    question: "What is one bad habit you are trying to improve on?",
    checked: false,
  },
  {
    id: 46,
    question: "What is your favourite month of the year and why?",
    checked: false,
  },
  {
    id: 47,
    question:
      "In a hypothetical game of hide and seek on your school property, what would be the best hiding spot and why?",
    checked: false,
  },
  {
    id: 48,
    question: "What is your most prized material possession?",
    checked: false,
  },
  { id: 49, question: "What store do you like to shop at?", checked: false },
  {
    id: 50,
    question:
      "What kind of new technology do you think will exist in 10 years from now?",
    checked: false,
  },
  { id: 51, question: "What is your favourite drink?", checked: false },
  {
    id: 52,
    question:
      "What is your favourite fruit? What is your least favourite fruit?",
    checked: false,
  },
  { id: 53, question: "What is your pet peeve?", checked: false },
  {
    id: 54,
    question: "If you could have 1 home rule at home, what would it be?",
    checked: false,
  },
  {
    id: 55,
    question:
      "Would you rather have kids only or pets only when you are an adult? If so, how many?",
    checked: false,
  },
  {
    id: 56,
    question: "What is a food craving you sometimes get?",
    checked: false,
  },
  {
    id: 57,
    question:
      "If you could spend a day as an animal, which animal would it be and why?",
    checked: false,
  },
  {
    id: 58,
    question:
      "If you could spend a day with an animal, which animal would it be and why?",
    checked: false,
  },
  { id: 59, question: "What is something that scares you?", checked: false },
  {
    id: 60,
    question:
      "Which planet would you like to live on and why? Use your imagination!",
    checked: false,
  },
  {
    id: 61,
    question: "Tell us about a time you got lost and what happened.",
    checked: false,
  },
  {
    id: 62,
    question:
      "Do you like spicy food? If so, what is the spiciest food you have ever eaten? (or seen someone eat?) ",
    checked: false,
  },
  {
    id: 63,
    question: "What animal or creature would you NOT want to encounter?",
    checked: false,
  },
  {
    id: 64,
    question: "If you were to be famous, what would you like to be famous for?",
    checked: false,
  },
  { id: 65, question: "What is your favourite dessert?", checked: false },
  {
    id: 66,
    question:
      "If you had to make a house out of food, what kind of food would it be?",
    checked: false,
  },
  {
    id: 67,
    question:
      "Would you ever get a tattoo one day? And if so, what do you think you might get and where?",
    checked: false,
  },
  {
    id: 68,
    question:
      "If you could change the ending of any movie, what movie would it be? What would the new ending be?",
    checked: false,
  },
  {
    id: 69,
    question: "What is the best and worst sounds to wake up from?",
    checked: false,
  },
  {
    id: 70,
    question: "What is the most interesting place you have ever slept?",
    checked: false,
  },
  {
    id: 71,
    question: "What is one thing you are not good at keeping clean or neat?",
    checked: false,
  },
  {
    id: 72,
    question: "What is one thing you like to keep really clean and neat?",
    checked: false,
  },
  {
    id: 73,
    question: "If you opened up a store what would you sell?",
    checked: false,
  },
  {
    id: 74,
    question: "What is your favourite thing to do on a sunny day?",
    checked: false,
  },
  {
    id: 75,
    question: "What is your favourite thing to do on a rainy day?",
    checked: false,
  },
  {
    id: 76,
    question: "What is your favourite thing to do on a snowy day?",
    checked: false,
  },
  {
    id: 77,
    question:
      "Would you rather be able to float/sit on clouds or breath underwater?",
    checked: false,
  },
  {
    id: 78,
    question:
      "Would you rather have a pause or a rewind button in your life? Why?",
    checked: false,
  },
  {
    id: 79,
    question: "Would you rather have an invisibility cloak or a time turner?",
    checked: false,
  },
  {
    id: 80,
    question: "Describe the best day OR the worst day.",
    checked: false,
  },
  {
    id: 81,
    question:
      "If you had to delete all your apps on your phone or ipad, except for 3, which would you choose?",
    checked: false,
  },
  { id: 82, question: "What dog breed would you be? Why?", checked: false },
  {
    id: 83,
    question:
      "If a genie could grant you one skill you currently do not have, what would it be?",
    checked: false,
  },
  {
    id: 84,
    question:
      "Would you rather spend your whole life underground or underwater?",
    checked: false,
  },
  {
    question: "Would you rather be a wizard or a superhero?",
    checked: false,
  },
  {
    id: 85,
    question:
      "If you could create a new holiday, what would it be, and how would you celebrate it?",
    checked: false,
  },
  {
    id: 86,
    question: "Do you think the earth is the only planet with life?",
    checked: false,
  },
  {
    id: 87,
    question:
      "Which two animals do you think would be at a similar speed for a race?",
    checked: false,
  },
  {
    id: 88,
    question:
      "Would you rather understand animals or be able to speak every human language?",
    checked: false,
  },
  {
    id: 89,
    question:
      "If you got $100, and you HAD to use it today, what you would you do with it?",
    checked: false,
  },
  {
    id: 90,
    question:
      "If you could only have three toppings on a stack of pancakes, what would they be?",
    checked: false,
  },
  {
    id: 91,
    question: "What is your favourite popsicle flavour?",
    checked: false,
  },
  {
    id: 92,
    question:
      "If you could have any hairstyle and haircolor for a day, what would you do?",
    checked: false,
  },
  { id: 93, question: "Describe your dream house.", checked: false },
  {
    id: 94,
    question:
      "Would you rather have one real get out of jail free card or a key that opens any door?",
    checked: false,
  },
  {
    id: 95,
    question: "Would you rather be a pro sports player or a professional chef?",
    checked: false,
  },
  {
    id: 96,
    question: "Would you rather be a pilot or a submarine driver?",
    checked: false,
  },
  {
    id: 97,
    question:
      "Would you rather have a quiet night at home in your room, or a fun evening with friends?",
    checked: false,
  },
  {
    id: 98,
    question:
      "You open a treasure box… would you rather find jewels & gold (that you can not use to buy a pet animal), or a pet animal? ",
    checked: false,
  },
  {
    id: 99,
    question:
      "If the best sandwhich in the world only had three ingredients inside, what would those ingredients be?",
    checked: false,
  },
  {
    id: 100,
    question:
      "Would you rather have a magic crayon that can bring drawings to life or a flying skateboard?",
    checked: false,
  },
  {
    id: 101,
    question:
      "If you could visit any place in the world, where would you go and what would you do there?",
    checked: false,
  },
  {
    id: 102,
    question: "What's your favorite book, and why do you like it so much?",
    checked: false,
  },
  {
    id: 103,
    question:
      "If you could have any animal as a pet, real or imaginary, what would it be?",
    checked: false,
  },
  {
    id: 104,
    question: "What's the silliest thing that has ever made you laugh?",
    checked: false,
  },
  {
    id: 105,
    question:
      "If you could have any superpower, what would it be and how would you use it?",
    checked: false,
  },
  {
    id: 106,
    question:
      "Would you rather have a robot friend or a talking animal friend?",
    checked: false,
  },
  {
    id: 107,
    question: "What's your favorite game to play with your friends?",
    checked: false,
  },
  {
    id: 108,
    question:
      "If you could design your own ice cream flavor, what would it be called and what ingredients would it have?",
    checked: false,
  },
  {
    id: 109,
    question: "What's the most exciting adventure you've ever been on?",
    checked: false,
  },
  {
    id: 110,
    question:
      "If you could travel back in time and meet a famous historical person, who would it be?",
    checked: false,
  },
  {
    id: 111,
    question:
      "What's your favorite thing to do with your family on the weekends?",
    checked: false,
  },
  {
    id: 112,
    question:
      "If you had a robot to help you with chores, what tasks would you have it do?",
    checked: false,
  },
  {
    id: 113,
    question: "What's the most interesting bug or insect you've ever seen?",
    checked: false,
  },
  {
    id: 114,
    question:
      "If you could visit any fictional world from a movie or book, where would you go?",
    checked: false,
  },
  {
    id: 115,
    question:
      "What's your favorite season, and what do you like to do during that season?",
    checked: false,
  },
  {
    id: 116,
    question:
      "If you could have a secret hideout, where would it be, and what would it look like?",
    checked: false,
  },
  {
    id: 117,
    question: "What's your favorite outdoor activity to do on a sunny day?",
    checked: false,
  },
  {
    id: 118,
    question: "If you could learn any new skill or hobby, what would it be?",
    checked: false,
  },
  {
    id: 119,
    question: "What's your favorite bedtime story, and why do you like it?",
    checked: false,
  },
  {
    id: 120,
    question:
      "If you could have a magical adventure with a talking animal, where would you go and what would you do?",
    checked: false,
  },
  {
    id: 121,
    question:
      "What's your favorite fruit to eat, and how do you like to eat it?",
    checked: false,
  },
  {
    id: 122,
    question:
      "If you could have a picnic with any three people, real or imaginary, who would they be?",
    checked: false,
  },
  {
    id: 123,
    question: "What's your favorite way to spend a rainy day indoors?",
    checked: false,
  },
  {
    id: 124,
    question:
      "If you could have a talking pet, which animal would it be and what would you talk about?",
    checked: false,
  },
  {
    id: 125,
    question:
      "What's your favorite kind of music or song, and why do you like it?",
    checked: false,
  },
  {
    id: 126,
    question:
      "If you could have a magical adventure with a talking tree, where would you go and what would you discover?",
    checked: false,
  },
  {
    id: 127,
    question: "What's your favorite color, and what do you like to do with it?",
    checked: false,
  },
  {
    id: 128,
    question:
      "If you could visit any planet in our solar system, which one would it be, and why?",
    checked: false,
  },
  {
    id: 129,
    question: "What's your favorite way to help others or make people smile?",
    checked: false,
  },
  {
    id: 130,
    question:
      "If you could have a magical adventure with a talking object, what would the object be, and where would it take you?",
    checked: false,
  },
  {
    id: 131,
    question: "What's your favorite way to be creative and express yourself?",
    checked: false,
  },
  {
    id: 132,
    question:
      "If you could have a time-traveling adventure, where and when would you go, and why?",
    checked: false,
  },
  {
    id: 133,
    question:
      "What's your favorite thing to do with your family during the holidays?",
    checked: false,
  },
  {
    id: 134,
    question:
      "If you could have a conversation with any animal, what would you ask them?",
    checked: false,
  },
  {
    id: 135,
    question: "What's your favorite way to spend a snow day?",
    checked: false,
  },
  {
    id: 136,
    question:
      "If you could go on a treasure hunt, where would you look for hidden treasures?",
    checked: false,
  },
  {
    id: 137,
    question: "What's your favorite way to relax and unwind after a busy day?",
    checked: false,
  },
  {
    id: 138,
    question:
      "If you could visit any famous landmark or historical site, where would you go?",
    checked: false,
  },
  {
    id: 139,
    question: "What's your favorite way to show kindness to others?",
    checked: false,
  },
  {
    id: 140,
    question:
      "If you could have a magic backpack that can carry anything, what would you keep inside it?",
    checked: false,
  },
  {
    id: 141,
    question: "What's your favorite way to make new friends and have fun",
  },
];

export default morningqs;
