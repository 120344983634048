import React, { useState } from "react";

function ShapeOfDay({ day, html, handleChange, currentUserFromApi }) {
  const lowercaseDay = day.toLowerCase();
  const valueFromCurrentUser = currentUserFromApi.html[lowercaseDay];

  const [isEditable, setIsEditable] = useState(false);

  const [editedValue, setEditedValue] = useState(valueFromCurrentUser);

  function handleSaveChanges() {
    currentUserFromApi.html[lowercaseDay] = editedValue;

    const patchData = {
      html: currentUserFromApi.html,
    };

    const requestPatchObj = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patchData),
    };

    fetch(
      `https://next-morningmoodz-api.vercel.app/api/${currentUserFromApi._id}`,
      requestPatchObj
    )
      .then((response) => {
        if (response.ok) {
          // console.log("User data updated successfully");
          setIsEditable(false);
        } else {
          // console.error("Failed to update user data");
          setIsEditable(false); // REMOVE THIS LATER!!!
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  return (
    <div className="builder-dayOfWeek">
      <h1 className="builder-dayOfWeek-text">{day}</h1>
      {/* <Title className="custom-class">{day}</Title> */}
      {isEditable ? (
        <textarea
          className="builder-input"
          style={{
            color: "black",
            padding: "1rem",
            fontFamily: "Quicksand",
            fontSize: "1.8rem",
            border: "2px dotted black",
            borderRadius: "15px",
            overflow: "scroll",
          }}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
        />
      ) : (
        <div
          style={{
            overflow: "scroll",
            color: "black",
            padding: "1rem",
            fontFamily: "Quicksand",
            fontSize: "1.8rem",
            border: "2px dotted black",
            borderRadius: "15px",
            whiteSpace: "pre-line",
          }}
          className="builder-input"
        >
          {valueFromCurrentUser}
        </div>
      )}
      {isEditable ? (
        <button className="save-changes-builder" onClick={handleSaveChanges}>
          Save Changes
        </button>
      ) : (
        <button
          className="edit-changes-builder"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </button>
      )}
    </div>
  );
}

export default ShapeOfDay;
